/* eslint-disable */
import Constants       from "../flux/Constants";
import {trackCTAClick} from "../utils/GAHelper";

function onReadyHandler(categories = [], urls = {}, language = 'de', isMobile = false) {
	var cookieconsent     = window.cookieconsent = initCookieConsent();
	var urlDataProtection = urls.dataProtectionUrl;
	var descriptionDE     = 'Wir verwenden Cookies um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Datenschutzerklärung</a>.' + '<br/><br/>Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.';
	var descriptionEN     = 'We use cookies to make your experience on our website as pleasant as possible and to improve our product. For more information on our data collection practices, see our <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Data protection</a>.' + '<br/><br/>You can decide which cookies you allow or reject. Click on "Settings" to get a list of all categories. By clicking on "Accept all" you agree to the use of cookies.';
	let descriptionES     = 'Utilizamos cookies para que tu experiencia en nuestro sitio web sea lo más agradable posible y para mejorar nuestro producto. Para más información sobre nuestras prácticas de recopilación de datos, consulta nuestra <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Política de privacidad</a>.' + '<br/><br/>Puedes decidir qué cookies aceptar o rechazar. Haz clic en "Preferencias" para ver una lista de todas las categorías. Al hacer clic en "Aceptar todas", aceptas el uso de cookies.';
	if (isMobile) {
		descriptionDE = 'Wir verwenden Cookies um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen: <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Datenschutzerklärung</a>.' + '<br/><br/>Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.';
		descriptionEN = 'We use cookies to make your experience on our website as pleasant as possible and to improve our product. For our data collection practices: <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Data protection</a>.' + '<br/><br/>You can decide which cookies you allow or reject. By clicking on "Accept all" you agree to the use of cookies.';
		descriptionES = 'Utilizamos cookies para que tu experiencia en nuestro sitio web sea lo más agradable posible y para mejorar nuestro producto. Para más información: <a aria-label="Cookie policy" class="cc-link" href="' + urlDataProtection + '" target="_blank">Política de privacidad</a>.' + '<br/><br/>Puede decidir qué cookies aceptar o rechazar. Al hacer clic en "Aceptar todas", aceptas el uso de cookies.';
	}

	var blocksDE = [
		{
			title:       'Cookie Nutzung',
			description: 'Unsere Cookies fallen in folgende Kategorien'
		}
	];
	var blocksEN = [
		{
			title:       'Using cookies',
			description: 'Our cookies fall into the following categories'
		}
	];
	
	var blocksES = [
		{
			title:       'Uso de cookies',
			description: 'Nuestras cookies pertenecen a las siguientes categorías'
		}
	];

	if (categories.indexOf(Constants.CookieCategories.NECESSARY) > -1) {
		blocksDE.push({
			title:       'Essentielle Cookies',
			description: 'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',
			toggle:      {
				value:    Constants.CookieCategories.NECESSARY,
				enabled:  true,
				readonly: true
			}
		});
		blocksEN.push({
			title:       'Necessary cookies',
			description: 'These cookies are necessary for the website to function properly.',
			toggle:      {
				value:    Constants.CookieCategories.NECESSARY,
				enabled:  true,
				readonly: true
			}
		});
		blocksES.push({
			title:       'Cookies esenciales',
			description: 'Estas cookies son necesarias para que el sitio web funcione correctamente.',
			toggle:      {
				value:    Constants.CookieCategories.NECESSARY,
				enabled:  true,
				readonly: true
			}
		});
	}

	if (categories.indexOf(Constants.CookieCategories.FUNCTIONAL) > -1) {
		blocksDE.push({
			title:       'Funktionale Cookies',
			description: 'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie zB. die Länderauswahl.',
			toggle:      {
				value:    Constants.CookieCategories.FUNCTIONAL,
				enabled:  false,
				readonly: false
			}
		});
		blocksEN.push({
			title:       'Functional cookies',
			description: 'These cookies improve the user experience on our website by remembering your preferred settings, such as the country selection.',
			toggle:      {
				value:    Constants.CookieCategories.FUNCTIONAL,
				enabled:  false,
				readonly: false
			}
		});
		blocksES.push({
			title:       'Cookies funcionales',
			description: 'Estas cookies mejoran la experiencia del usuario en nuestro sitio web al recordar su configuración preferida, como la selección del país.',
			toggle:      {
				value:    Constants.CookieCategories.FUNCTIONAL,
				enabled:  false,
				readonly: false
			}
		});
	}

	var cookieTable = [
		{
			col1:     '^_ga',
			col2:     'google.com',
			col3:     '2 years',
			col4:     'Google Analytics Tracking',
			domain:   '.visit-x.net',
			is_regex: true
		},
		{
			col1:   '_gid',
			col2:   'google.com',
			col3:   '1 day',
			col4:   'Google Analytics Tracking',
			domain: '.visit-x.net',
		}
	];
	if (categories.indexOf(Constants.CookieCategories.ANALYTICS) > -1) {
		blocksDE.push({
			title:        'Analyse und Optimierung',
			description:  'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
			toggle:       {
				value:    Constants.CookieCategories.ANALYTICS,
				enabled:  false,
				readonly: false,
				reload:   'on_disable',
			},
			cookie_table: cookieTable
		});
		blocksEN.push({
			title:        'Analysis and optimization',
			description:  'These cookies are used to analyze the use of our website and to test various optimizations so that our product continues to develop.',
			toggle:       {
				value:    Constants.CookieCategories.ANALYTICS,
				enabled:  false,
				readonly: false,
				reload:   'on_disable',
			},
			cookie_table: cookieTable
		});
		blocksES.push({
			title:        'Análisis y optimización',
			description:  'Estas cookies se utilizan para analizar el uso de nuestro sitio web y probar diversas optimizaciones para que nuestro producto siga desarrollándose.',
			toggle:       {
				value:    Constants.CookieCategories.ANALYTICS,
				enabled:  false,
				readonly: false,
				reload:   'on_disable',
			},
			cookie_table: cookieTable
		});
	}

	// last block
	blocksDE.push({
		title:       'Mehr Informationen',
		description: 'Für weitere Informationen zum Thema Datenschutz lies unsere <a class="cc-link" href="' + urlDataProtection + '">Datenschutzerklärung</a>.',
	});
	blocksEN.push({
		title:       'More information',
		description: 'For more information on data protection, read our <a class="cc-link" href="' + urlDataProtection + '">Data protection</a>.',
	});
	blocksES.push({
		title:       'Más información',
		description: 'Para más información sobre la protección de datos, lee nuestra <a class="cc-link" href="' + urlDataProtection + '">política de privacidad</a>.',
	});

	const languages = {
		de: {
			consent_modal:  {
				title:         'Wir nutzen Cookies!',
				description:   descriptionDE,
				primary_btn:   {
					text: 'Alle akzeptieren',
					role: 'accept_all'              // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Einstellungen',
					role: 'settings'                // 'settings' or 'accept_necessary'
				}
			},
			settings_modal: {
				title:                'Cookie Einstellungen',
				save_settings_btn:    'Speichern',
				accept_all_btn:       'Alle akzeptieren',
				reject_all_btn:       'Alle ablehnen',       // optional, [v.2.5.0 +]
				cookie_table_headers: [
					{col1: 'Name'},
					{col2: 'Domain'},
					{col3: 'Expiration'},
					{col4: 'Description'},
					{col5: 'Type'}
				],
				blocks:               blocksDE
			}
		},
		en: {
			consent_modal:  {
				title:         'We use cookies!',
				description:   descriptionEN,
				primary_btn:   {
					text: 'Accept all',
					role: 'accept_all'              // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Settings',
					role: 'settings'                // 'settings' or 'accept_necessary'
				}
			},
			settings_modal: {
				title:                'Cookie settings',
				save_settings_btn:    'Save',
				accept_all_btn:       'Accept all',
				reject_all_btn:       'Reject all',       // optional, [v.2.5.0 +]
				cookie_table_headers: [
					{col1: 'Name'},
					{col2: 'Domain'},
					{col3: 'Expiration'},
					{col4: 'Description'},
					{col5: 'Type'}
				],
				blocks:               blocksEN
			}
		},
		es: {
			consent_modal:  {
				title:         '¡Utilizamos cookies!',
				description:   descriptionES,
				primary_btn:   {
					text: 'Aceptar todo',
					role: 'accept_all'              // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Configuración',
					role: 'settings'                // 'settings' or 'accept_necessary'
				}
			},
			settings_modal: {
				title:                'Configuración de cookies',
				save_settings_btn:    'Guardar',
				accept_all_btn:       'Aceptar todo',
				reject_all_btn:       'Rechazar todo',       // optional, [v.2.5.0 +]
				cookie_table_headers: [
					{col1: 'Nombre'},
					{col2: 'Dominio'},
					{col3: 'Caducidad'},
					{col4: 'Descripción'},
					{col5: 'Tipo'}
				],
				blocks:               blocksES
			}
		}
	};

	languages['nl'] = languages['en'];

	cookieconsent.run({
		autorun:              true,
		current_lang:         language,
		autoclear_cookies:    true,
		page_scripts:         true,
		remove_cookie_tables: true,

		onFirstAction: function(user_preferences) {
			if (user_preferences.accepted_categories.includes("analytics")) {
				trackCTAClick("cookie-consent");
			}
		},

		onAccept: function(cookie, sec) {
			// ... cookieconsent accepted
		},

		onChange: function(cookie, changed_preferences) {
			// ... cookieconsent preferences were changed
			// If analytics category's status was changed ...
			if (changed_preferences.indexOf(Constants.CookieCategories.ANALYTICS) > -1) {

				// If analytics category is disabled ...
				if (!cookieconsent.allowedCategory(Constants.CookieCategories.ANALYTICS)) {

					// Disable gtag ...
					window.dataLayer = window.dataLayer || [];

					function gtag() {
						dataLayer.push(arguments);
					}

					gtag('consent', 'default', {
						'ad_storage':        'denied',
						'analytics_storage': 'denied'
					});
				}
			} else {
				console.log('here');
			}
		},

		languages: languages
	});
}

function run(...args) {
	const _onReady = onReadyHandler.bind(this, ...args);
	if (document.readyState !== 'loading') {
		_onReady();
	} else {
		window.addEventListener('DOMContentLoaded', _onReady, false);
	}
}

export {run};